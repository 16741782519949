<template>
  <div class="login-page">
    <b-container>
      <Widget
        class="mx-auto"
        title="<h3 class='mt-0'>Please confirm your email address</h3>"
        custom-header
      >
        <form class="mt" @submit.prevent="unsubscribe">
          <div class="form-group">
            <input
              ref="username"
              class="form-control no-border"
              required
              type="text"
              name="email"
              placeholder="Email"
            >
          </div>
          <div class="clearfix">
            <div class="btn-toolbar float-right">
              <b-button
                id="login"
                type="submit"
                size="sm"
                variant="inverse"
                :disabled="processing"
                name="login"
              >Unsubscribe</b-button>
            </div>
          </div>
        </form>
      </Widget>
    </b-container>
    <footer v-if="false" class="footer">
      2019 &copy; Cast Iron Media.
    </footer>
  </div>
</template>

<script>
import WebMessage from '@/models/WebMessage'
import Widget from '@/components/Widget/Widget.vue'

export default {
  name: 'Unsubscribe',
  components: { Widget },
  props: ['id'],
  data() {
    return {
      processing: false,
    }
  },
  created() {},
  methods: {
    unsubscribe() {
      const email = this.$refs.username.value
      this.processing = true

      this.$axios
        .post(`${this.$api}report/${this.id}/unsubscribe`, {
          email,
        })
        .then(() => {
          this.processing = false
          const message = 'Your email was removed from this report.'

          WebMessage.success(message)
        })
        .catch(error => {
          let message = 'We were not able to process your request, please try again later'

          if (error.response.status == 422 || error.response.status == 404) {
            message = 'Email or report not found, please contact your account manager for additional assistance.'
          }

          WebMessage.error(message)

          this.processing = false
        })
    },
  },
}
</script>

<style src="./Unsubscribe.scss" lang="scss" scoped/>
